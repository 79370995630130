<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="部门" prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          filterable
          @change="didChange"
          placeholder="请选择部门"
          style="width: 100%"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="班组" prop="tid">
        <el-select
          v-model="dataForm.tid"
          clearable
          filterable
          @change="tidChange"
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择班组' : '请先选择部门'"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="员工" prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择员工' : '请先选择部门'"
          @change="eidChange"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="场站" prop="stationId">
        <el-select
          v-model="dataForm.stationId"
          clearable
          filterable
          style="width: 100%"
          placeholder="请先选择场站"
          @change="stationChange"
        >
          <el-option
            v-for="item in stationList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="dataForm.type == 1 || !dataForm.id"
        label="任务"
        prop="task"
      >
        <el-input
          v-model="dataForm.task"
          placeholder="任务"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="dataForm.id"
        label="存在问题"
        prop="problemDescription"
      >
        <editor ref="editor1" v-model="dataForm.problemDescription"></editor>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="意见与建议" prop="yijian">
        <editor ref="editor2" v-model="dataForm.yijian"></editor>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="巡查时间" prop="xunchaDate">
        <el-date-picker
          style="width: 100%"
          v-model="dataForm.xunchaDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="date"
          placeholder="选择日期"
          clearable
          disabled
        ></el-date-picker>
      </el-form-item>

      <el-form-item v-if="dataForm.id" label="状态" prop="status">
        <el-select v-model="dataForm.status" disabled style="width: 100%">
          <el-option
            v-for="item in patrol_statusOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="dataForm.status > 1" label="审核意见" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          placeholder="审核意见"
          disabled
        ></el-input>
      </el-form-item>
    </el-form>
    <div v-if="flag == 1" style="padding: 10px 0; font-size: 16px">
      检查项目详情
    </div>
    <el-table
      v-if="flag == 1"
      :data="dataForm.jsonData"
      border
      style="width: 100%"
    >
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="选项" header-align="center" width="380">
        <template slot-scope="scope">
          <div>
            <el-radio-group
              class="opt-radio-group"
              v-model="scope.row.optionIndex"
            >
              <el-radio label="0" disabled>符合</el-radio>
              <el-radio label="1" disabled>不符合</el-radio>
              <el-radio label="2" disabled>未检查</el-radio>
              <el-radio label="3" disabled>不适用</el-radio>
            </el-radio-group>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="dataForm.id">
      <p>日志记录</p>
      <el-input
        type="textarea"
        v-model="dataForm.log"
        disabled
        rows="6"
      ></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="flag != 1"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
  
<script>
import Editor from "@/components/editor.vue";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      // 部门列表
      departmentList: [],
      //   状态
      patrol_statusOpt: [
        { label: "已派发巡查任务", value: 0 },
        { label: "已填写巡查情况", value: 1 },
        { label: "审核不合格，重新填写", value: 2 },
        { label: "已归档入库", value: 3 },
      ],

      //   班组列表
      teamList: [],
      //   员工列表
      employeeList: [],
      stationList: [],
      flag: 0,
      dataForm: {
        id: "",
        did: "",
        dname: "",
        tid: "",
        tname: "",
        ename: "",
        eid: "",
        stationId: "",
        stationName: "",
        task: "",
        problemDescription: "",
        yijian: "",
        xunchaDate: "",
        status: 0,
        remarks: "",
        log: "",
        type: 1,
        jsonData: [],
      },
      dataRule: {
        did: [{ required: true, message: "部门不能为空", trigger: "blur" }],
        eid: [{ required: true, message: "员工不能为空", trigger: "blur" }],
        address: [{ required: true, message: "地点不能为空", trigger: "blur" }],
        task: [{ required: true, message: "任务不能为空", trigger: "blur" }],
        problemDescription: [
          { required: true, message: "问题描述不能为空", trigger: "blur" },
        ],
        stationId: [
          { required: true, message: "场站不能为空", trigger: "blur" },
        ],
      },

      select_load: false,
    };
  },

  methods: {
    // 获取场站列表
    getStationList() {
      this.$http.station.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.stationList = res.data.list || [];
        } else {
          this.stationList = [];
        }
      });
    },
    // 选择的场站发生变化
    stationChange(e) {
      this.stationList.forEach((item) => {
        if (e == item.id) {
          this.dataForm.stationName = item.name;
        }
      });
    },
    init(id, flag) {
      this.flag = flag;
      this.getStationList();
      this.getDepartmentList(id);
      this.dataForm.jsonData = "";

      this.dataForm.id = id || 0;
      this.visible = true;

      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();

        // 修改
        if (this.dataForm.id) {
          this.$http.patrol.info({ id: this.dataForm.id }).then((res) => {
            if (res.data) {
              if (res.data.jsonData) {
                res.data.jsonData = JSON.parse(res.data.jsonData);
              }

              this.dataForm = res.data;
              this.$refs.editor1.destroy();
              this.$refs.editor1.init(this.dataForm.problemDescription);
              this.$refs.editor2.destroy();
              this.$refs.editor2.init(this.dataForm.yijian);
              this.getEmployeeList();
            }
          });
        } else {
          this.$refs.editor1.destroy();
          this.$refs.editor1.init(this.dataForm.problemDescription);
          this.$refs.editor2.destroy();
          this.$refs.editor2.init(this.dataForm.yijian);
        }
      });
    },
    remoteMethod(e) {
      console.log(e);
    },
    // 获取部门列表
    async getDepartmentList(e) {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (res.data) {
          this.departmentList = res.data.list || [];
        } else {
          this.departmentList = [];
        }

        // 修改页面
        if (e) {
          this.getTeamList();
        }
      });
    },
    // 获取班组列表
    getTeamList() {
      let teamListDF = {
        did: this.dataForm.did,
        page: 1,
        limit: 99999,
      };

      this.$http.team.list(teamListDF).then((res) => {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.teamList = res.data.list;
        } else {
          this.teamList = [];
          this.dataForm.tid = "";
        }
      });
    },

    // 获取员工列表
    getEmployeeList() {
      let employeeListDF = {
        did: this.dataForm.did,
        tid: this.dataForm.tid,
        page: 1,
        limit: 99999,
      };

      this.$http.employee.list(employeeListDF).then((res) => {
        if (res.data) {
          this.employeeList = res.data.list || [];
        } else {
          this.employeeList = [];
        }
      });
    },

    // 选择的部门发生变化
    didChange(e) {
      if (e) {
        this.getTeamList();
        this.getEmployeeList();

        this.dataForm.dname = "";

        // 获取当前选择的部门名字
        let result = this.departmentList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.dname = result[0].name;
        }
      } else {
        this.teamList = [];
        this.dataForm.tid = "";
        this.employeeList = [];
        this.dataForm.eid = "";
      }
    },
    // 选择的班组发生变化
    tidChange(e) {
      this.dataForm.tname = "";

      if (this.dataForm.tid) {
        if (e) {
          // 获取当前选择的班组名字
          let result = this.teamList.filter((ele) => {
            return ele.id == e;
          });
          if (result.length > 0) {
            this.dataForm.tname = result[0].name;
          }
        }
      } else {
        this.dataForm.eid = "";
      }
      this.employeeList = [];
      this.getEmployeeList();
    },
    // 选择的员工发生变化
    eidChange(e) {
      this.dataForm.ename = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.employeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.ename = result[0].name;
        }
      }
    },
    // 提交表单
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          console.log(requestDate);

          if (this.dataForm.id) {
            // 修改
            this.$http.patrol.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.patrol.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.opt-radio-group {
  .el-radio:not(:first-child) {
    margin-top: 15px;
  }
}
</style>